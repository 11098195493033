.fc-prev-button,.fc-next-button,.fc-dayGridMonth-button,.fc-timeGridWeek-button,.fc-timeGridDay-button{
    background-color: white ;
    color: #407FEA 
}
.fc-next-button:hover,.fc-prev-button:hover{
    background-color: #407FEA !important;
    color: white !important
}
.fc-dayGridMonth-button:hover,.fc-timeGridWeek-button:hover,.fc-timeGridDay-button:hover{
    background-color: #3266BB !important;
    color: white !important
}
.fc-day-today {
    background-color: #edf5fa !important;
  }

  .website-custom-menu{
    padding: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #f2f2f2;
    display: flex;
    align-items: center;
    color: #495057;
  }
  .website-custom-menu:hover{
  
    color: #558FED;
  }
  .password_field::-ms-reveal,
  .password_field::-ms-clear {
    display: none;
  }
  

  
  .calendar-input::-webkit-inner-spin-button,
  .calendar-input::-webkit-calendar-picker-indicator {
    font-size: 17px; /* Change this value to adjust the font size */
  }
  input.calendar-input::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;

    right: 0;
    top: 0;
    width: auto;
}
/* input.calendar-input[type="time"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
} */
#Emptybox {
    /* width: "100%"; */
    /* height: 130px; */
    background-color: white;
    /* justify-content: center;
    align-items: center;
    cursor: pointer; */
}
#Emptybox:hover {
    background-color:#f5f5f5;
}
.Listmodal:hover  {
    background-color:#f5f5f5;
}

.createnewhover:hover{
    background-color:#f5f5f5;
}
.pac-container {
    z-index: 10000 !important;
}
#authheader{
    height: 80px;
}
#authheaderlogo{
    height:45px;
    width:52px;
}
#authheadertext{
font-size: 12px;
}
#signupheader{
    font-size: 3rem;
    /* padding-top: 50px, */
}
#leftsigninbox{
    display: flex;
    justify-content: flex-end;
  
}
.modalbtns:hover{
    background-color: #3367d6;
    color:white;
}

#onboardinglist:after{
content: " ";
border-top: 11px solid #222;
border-left: 8px solid transparent;
border-right: 8px solid transparent;
position: relative;
top: 111px;
right: -140px;
}
@media only screen and (max-width: 1024px) {
    #signupheader{
        font-size: 1.8rem;
        /* padding-top: 40px, */
    }
}

@media only screen and (max-width: 700px) {
    #authheader{
        height: 60px;
    } 
    #authheaderlogo{
        height:35px;
    width:42px;
    }
    #authheadertext{
        font-size: 10px;
        }
}
@media only screen and (max-width: 576px){
    #leftsigninbox{
        display: flex;
        justify-content: center;
    }
}


