:root {
  --widthh: 1164;
}
.notyf__toast {
  max-width: 500em !important;
  /* width: 260px/var(--widthh)*100; */
  width: calc( calc(1 - calc(260/var(--widthh))) * 100%);
}
.notyf__toast2 {
  max-width: 500em !important;
  /* width: 260px/var(--widthh)*100; */
  width: 100%;
}
.notyf__ripple {
  height: 60em;
  width: 2000em;
}
/* .notyf__toast {
  max-width: 500em !important;
  width: 100% - (260/var(--widthhh))*100;
}
.notyf__ripple {
  height: 60em;
  width: 2000em;
} */
/* // 260px/width *100
// 260/width of screen *100 when sidebar is open 

// 100% when sidebar is closed */

  /* width: 100% - (260/709)*100;
  width: 100% - (260px);
width: calc(100vw) - calc(260/100)vw;
  width: 95vwv - (260/100)vw; */

